import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'

import 'regenerator-runtime/runtime'

import vueMoment from 'vue-moment';
import PubNubVue from 'pubnub-vue';
import {v4 as uuidv4} from 'uuid';

Vue.use(CoreuiVuePro)

Vue.use(vueMoment);
Vue.use(PubNubVue, { 
  subscribeKey: 'sub-c-1823ce26-76d4-11ec-87be-4a1e879706fb', 
  publishKey: 'pub-c-c7c24bed-9185-424d-8530-f40868a2f62b', 
  uuid: uuidv4()
});

Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
